"use client";

import Nav from "@litonarefin/components/Header/Nav";
import { Fragment, useState, memo, useEffect, useCallback, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import { getIcon } from "@litonarefin/utils/icons/index";
import { sanitize } from "@litonarefin/utils/miscellaneous";
import { signOut, useSession } from "next-auth/react";
import { getStyledIcon } from "@litonarefin/utils/getStyledIcon";
import { useGlobalCartContext } from "@litonarefin/context/CartContext";
import { getSourceSite } from "@litonarefin/utils/getSourceSite";

const Header = memo(
    ({
        hideMenu = false,
        headingText,
        individualLogo,
        individualLogoPath,
        finalMenu,
        isProductPage,
    }) => {
        const [isMenuVisible, setIsMenuVisible] = useState(false);
        const [mobileMenuOpen, setMobileMenuOpen] = useState(null);
        const [headerClass, setHeaderClass] = useState("");
        const [y, setY] = useState(0);
        const [isOpenAuthor, setIsOpenAuthor] = useState(false);
        const { cartCount } = useGlobalCartContext();

        const { data: session, status } = useSession();

        // console.log("session", session);

        const siteSource = getSourceSite();

        const cls = {};
        if (!!hideMenu) {
            cls.className = "jlt-header-hide";
        } else {
            cls.className = headerClass;
        }

        const logOut = async () => {
            signOut();
        };

        const handleScroll = useCallback(
            (e) => {
                const window = e.currentTarget;
                if (y > window.scrollY && window.pageYOffset > 100) {
                    if (window.pageYOffset > 100) {
                        setHeaderClass("jlt-is-sticky");
                    }
                }

                if (y < window.scrollY || window.pageYOffset < 100) {
                    if (window.pageYOffset < 100) {
                        setHeaderClass("");
                    } else {
                        setHeaderClass("jlt-header-tf");
                    }
                }
                setY(window.scrollY);
            },
            [y]
        );

        useEffect(() => {
            // setY(window.scrollY);

            if (!!hideMenu) {
                return;
            }

            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }, [handleScroll]);

        const wrapperRef = useRef(null);
        const mobileRef = useRef(null);

        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setIsOpenAuthor(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [wrapperRef]);

        useEffect(() => {
            /**
             * Mobile Menu
             */
            function handleClickOutside(event) {
                if (mobileRef.current && !mobileRef.current.contains(event.target)) {
                    setIsMenuVisible(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [mobileRef]);

        return (
            <Fragment>
                <header id="header" {...cls} style={isProductPage ? { background: "#fff" } : {}}>
                    {/* <header id="header"> */}
                    <div
                        id="container"
                        className="jlt-mx-auto md:jlt-max-w-[1280px] !jlt-px-4 md:jlt-px-8 jlt-flex jlt-items-center jlt-justify-between jlt-relative">
                        <div className="jlt-flex jlt-flex-shrink-0 jlt-items-center">
                            {/* !!siteLogo?.miniLogo && */}

                            {individualLogo && (
                                <div className="jlt-flex jlt-items-center jlt-gap-x-4 jlt-mr-4">
                                    <Link href={"/"}>
                                        <Image
                                            src={"/mini-logo.png"}
                                            alt={"Mini Logo"}
                                            width={32}
                                            height={32}
                                            sizes="100vw"
                                        />
                                    </Link>

                                    <span>{getIcon("arrowRightAlt")}</span>
                                </div>
                            )}

                            {individualLogo ? (
                                <div className="jlt-min-w-[148px]">
                                    <Link
                                        href={
                                            `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/${individualLogoPath}` ||
                                            "#"
                                        }>
                                        {/* <img
                                            className="jlt-block lg:jlt-hidden"
                                            src={individualLogo}
                                            alt={individualLogoPath || "Product Logo"}
                                            style={{
                                                objectFit: "contain",
                                                objectPosition: "left center",
                                            }}
                                        /> */}

                                        <img
                                            src={individualLogo}
                                            alt={individualLogoPath || "Product Logo"}
                                            width="148"
                                            height="36"
                                            style={{
                                                height: "36px",
                                                objectFit: "contain",
                                                objectPosition: "left center",
                                            }}
                                        />
                                    </Link>
                                </div>
                            ) : (
                                <Link href={"/"}>
                                    <Image
                                        className="jlt-block lg:jlt-hidden"
                                        src={"/logo.svg"}
                                        alt={"Logo"}
                                        width={224}
                                        height={20}
                                        priority={true}
                                        sizes={"100vw"}
                                    />
                                    <Image
                                        className="jlt-hidden lg:jlt-block"
                                        src={"/logo.svg"}
                                        alt={"Logo"}
                                        width={224}
                                        height={32}
                                        priority={true}
                                        sizes={"100vw"}
                                    />
                                </Link>
                            )}
                        </div>

                        <Fragment>
                            {!!hideMenu ? null : (
                                <div
                                    // ref={mobileRef}
                                    className={`${
                                        isMenuVisible
                                            ? "jlt-absolute jlt-top-[60px] lg:jlt-top-0 jlt-left-0 lg:jlt-relative jlt-px-9 lg:jlt-px-0 jlt-w-full lg:jlt-w-auto jlt-z-[999999] lg:jlt-z-0"
                                            : "jlt-hidden lg:jlt-block"
                                    }`}>
                                    <div className="jlt-bg-white lg:jlt-bg-transparent jlt-border-[1px] lg:jlt-border-[0]">
                                        <Nav
                                            isMenuVisible={isMenuVisible}
                                            mobileMenuOpen={mobileMenuOpen}
                                            setMobileMenuOpen={setMobileMenuOpen}
                                            finalMenu={finalMenu}
                                        />
                                        <div className="lg:jlt-hidden jlt-px-3 jlt-py-4">
                                            <Link
                                                href={"/cart"}
                                                className="jlt-text-sm jlt-font-medium jlt-text-[#16213E] group-hover:jlt-text-[#256EFF] jlt-transition jlt-duration-300 jlt-relative">
                                                <span>Cart</span>
                                                <div className="jlt-absolute -jlt-top-1 jlt-left-full jlt-bg-[#256EFF] jlt-rounded-full jlt-p-1 jlt-w-4 jlt-h-4 jlt-flex jlt-items-center jlt-justify-center jlt-text-white">
                                                    <span className="jlt-text-xs">{cartCount}</span>
                                                </div>
                                            </Link>
                                        </div>
                                        {/* Profile dropdown */}
                                        {isMenuVisible ? (
                                            <div className="lg:jlt-hidden jlt-px-3 jlt-py-4">
                                                {session?.user?.id ? (
                                                    <Link
                                                        href="/dashboard/orders"
                                                        className="jlt-flex jlt-items-center jlt-gap-2">
                                                        {session?.user?.avatarUrl ? (
                                                            <img
                                                                width={24}
                                                                height={24}
                                                                alt="avater"
                                                                className="jlt-rounded-full"
                                                                src={session?.user?.avatarUrl}
                                                            />
                                                        ) : null}
                                                        <span>{session?.user?.displayName}</span>
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        href={
                                                            siteSource == "wpadminify"
                                                                ? "/account"
                                                                : "/account/login"
                                                        }
                                                        className="jlt-text-sm jlt-font-medium jlt-text-[#16213E] group-hover:jlt-text-[#256EFF] jlt-transition jlt-duration-300">
                                                        <span>Account</span>
                                                    </Link>
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )}

                            {!hideMenu ? (
                                <div className="jlt-hidden lg:jlt-flex">
                                    {/* cart button */}
                                    <div className="jlt-relative jlt-inset-y-0 jlt-right-0 jlt-flex jlt-items-center">
                                        <Link
                                            href="/cart"
                                            className="jlt-rounded-full jlt-p-1 jlt-relative">
                                            <div className="jlt-absolute jlt-top-0 jlt-right-0 jlt-bg-[#256EFF] jlt-rounded-full jlt-p-1 jlt-w-4 jlt-h-4 jlt-flex jlt-items-center jlt-justify-center jlt-text-white">
                                                <span className="jlt-text-xs">{cartCount}</span>
                                            </div>
                                            {getIcon("cart")}
                                        </Link>
                                    </div>
                                    {/* Profile dropdown */}
                                    <div className="jlt-relative jlt-ml-6">
                                        {session?.user?.id ? (
                                            <div className="jlt-relative" ref={wrapperRef}>
                                                <img
                                                    className="jlt-rounded-full jlt-cursor-pointer jlt-border"
                                                    src={session?.user?.avatarUrl}
                                                    alt="Profile Image"
                                                    width={32}
                                                    height={32}
                                                    onClick={() => setIsOpenAuthor(!isOpenAuthor)}
                                                />
                                                {isOpenAuthor ? (
                                                    <div className="jlt-absolute jlt-top-12 jlt-right-[calc(100%-40px)] jlt-w-44 jlt-pb-1 jlt-rounded-lg jlt-bg-white jlt-shadow-[0px_0px_8px_rgba(214,214,214,0.78)] before:jlt-content-[''] before:jlt-absolute before:jlt-w-4 before:jlt-h-4 before:jlt-bg-white before:jlt-rotate-45 before:-jlt-top-1 before:jlt-right-4">
                                                        <div className="jlt-pt-4 jlt-px-4">
                                                            <p className="jlt-text-sm">
                                                                {session?.user?.displayName}
                                                            </p>
                                                        </div>
                                                        <hr className="jlt-mt-2 jlt-mb-1 jlt-mx-2" />
                                                        <div className="jlt-flex jlt-flex-col jlt-gap-1">
                                                            <Link
                                                                className="jlt-text-[#256EFF] jlt-border-l-0 hover:jlt-border-l-4 jlt-transition-all jlt-duration-100 jlt-border-[#256EFF] jlt-py-1 jlt-pl-4 jlt-flex jlt-items-center jlt-gap-2"
                                                                href={"/dashboard/orders"}>
                                                                {getStyledIcon(
                                                                    getIcon("orders"),
                                                                    "#3D79F2",
                                                                    "stroke",
                                                                    {
                                                                        width: "16px",
                                                                        height: "16px",
                                                                    }
                                                                )}
                                                                <span className="jlt-text-sm jlt-font-medium">
                                                                    Orders
                                                                </span>
                                                            </Link>
                                                            <hr className="jlt-mx-2" />
                                                            <Link
                                                                className="jlt-text-[#256EFF] jlt-border-l-0 hover:jlt-border-l-4 jlt-transition-all jlt-duration-100 jlt-border-[#256EFF] jlt-py-1 jlt-pl-4 jlt-flex jlt-items-center jlt-gap-2"
                                                                href={"/dashboard/downloads"}>
                                                                {getStyledIcon(
                                                                    getIcon("orders"),
                                                                    "#3D79F2",
                                                                    "stroke",
                                                                    {
                                                                        width: "16px",
                                                                        height: "16px",
                                                                    }
                                                                )}
                                                                <span className="jlt-text-sm jlt-font-medium">
                                                                    Downloads
                                                                </span>
                                                            </Link>
                                                            <hr className="jlt-mx-2" />
                                                            <Link
                                                                className="jlt-text-[#256EFF] jlt-border-l-0 hover:jlt-border-l-4 jlt-transition-all jlt-duration-100 jlt-border-[#256EFF] jlt-py-1 jlt-pl-4 jlt-flex jlt-items-center jlt-gap-2"
                                                                href={"/dashboard/support"}>
                                                                {getStyledIcon(
                                                                    getIcon("support"),
                                                                    "#3D79F2",
                                                                    "stroke",
                                                                    {
                                                                        width: "16px",
                                                                        height: "16px",
                                                                    }
                                                                )}
                                                                <span className="jlt-text-sm jlt-font-medium">
                                                                    Support
                                                                </span>
                                                            </Link>
                                                            <hr className="jlt-mx-2" />
                                                            <Link
                                                                className="jlt-text-[#256EFF] jlt-border-l-0 hover:jlt-border-l-4 jlt-transition-all jlt-duration-100 jlt-border-[#256EFF] jlt-py-1 jlt-pl-4 jlt-flex jlt-items-center jlt-gap-2"
                                                                href={"/dashboard/profile"}>
                                                                {getStyledIcon(
                                                                    getIcon("profile"),
                                                                    "#3D79F2",
                                                                    "stroke",
                                                                    {
                                                                        width: "16px",
                                                                        height: "16px",
                                                                    }
                                                                )}
                                                                <span className="jlt-text-sm jlt-font-medium">
                                                                    Profile
                                                                </span>
                                                            </Link>
                                                            <hr className="jlt-mx-2" />
                                                            <button
                                                                className="jlt-text-[#256EFF] jlt-border-l-0 hover:jlt-border-l-4 jlt-transition-all jlt-duration-100 jlt-border-[#256EFF] jlt-py-1 jlt-pl-4 jlt-pb-2 jlt-flex jlt-items-center jlt-gap-2"
                                                                onClick={() => logOut()}>
                                                                {getStyledIcon(
                                                                    getIcon("logout"),
                                                                    "#3D79F2",
                                                                    "fill",
                                                                    {
                                                                        width: "14px",
                                                                        height: "14px",
                                                                    }
                                                                )}
                                                                <span className="jlt-text-sm jlt-font-medium">
                                                                    Logout
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <Link
                                                href={
                                                    siteSource == "wpadminify"
                                                        ? "/account"
                                                        : "/account/login"
                                                }
                                                className="jlt-btn jlt-flex jlt-items-center jlt-justify-center jlt-rounded-full jlt-text-[13px] jlt-py-2 jlt-px-3 jlt-gap-x-2">
                                                <span>{getIcon("user")}</span>
                                                Account
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <p
                                    className="jlt-hide-menu-text"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitize(headingText),
                                    }}
                                />
                            )}
                        </Fragment>

                        {!hideMenu ? (
                            <div className="jlt-flex jlt-items-center lg:jlt-hidden">
                                {/* Mobile menu button */}
                                <button
                                    onClick={() => setIsMenuVisible(!isMenuVisible)}
                                    type="button"
                                    className="jlt-flex jlt-flex-col jlt-gap-y-1"
                                    aria-controls="mobile-menu"
                                    aria-expanded="false">
                                    <span
                                        className={`jlt-w-[23px] jlt-h-[3px] jlt-bg-[#ababab] jlt-transition jlt-duration-200 ${
                                            isMenuVisible
                                                ? "jlt-rotate-[45deg] jlt-relative jlt-top-[7px]"
                                                : ""
                                        }`}
                                    />
                                    <span
                                        className={`jlt-w-[23px] jlt-h-[3px] jlt-bg-[#ababab] ${
                                            isMenuVisible ? "jlt-hidden" : "jlt-block"
                                        }`}
                                    />
                                    <span
                                        className={`jlt-w-[23px] jlt-h-[3px] jlt-bg-[#ababab] jlt-transition jlt-duration-200  ${
                                            isMenuVisible ? "jlt-rotate-[-45deg]" : ""
                                        }`}
                                    />
                                </button>
                            </div>
                        ) : null}
                    </div>
                </header>
            </Fragment>
        );
    }
);

export default Header;
